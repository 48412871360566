html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;

  --primary-color: #6aaec4;
  --primary-color-hover: #c8e1e9;
  --primary-color-hover-light: #daebf1;

  --default-gray: #e1e4ea;
  --default-light-gray: #f2f4f7;
  --default-dark-gray: #808398;
  --default-font-color: #707070;

  --primary-color-highlight: #88eaf2;
  --secondary-color: #202340;
  --secondary-color-light: #426288;
  --disabled-color: #ccc;

  --font-family: 'Montserrat', sans-serif;
  --sm-breakpoint: 600px;

  --error-color: #e75050;
  --success-color: #68bb6a;
  --info-color: #3488eb;
  --warning-color: #ffa22e;
  --inprogress-color: #b4b4b4;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #202340;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.4;
  font-weight: 400;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

h1 {
  font-size: 50px;
  line-height: 1;
}

h2 {
  font-size: 40px;
  line-height: 1.2;
}

h3 {
  margin: 0px 0 1.5rem 0;
  text-align: left;
  color: #707070;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 500;
}

form label {
  font-size: 0.625rem;
  text-align: left;
  float: left;
  width: 100%;
}

.narrow-wrap {
  max-width: 600px;
  margin: auto;
}

#login-splash {
  text-align: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  margin-left: 620px;
  background-color: #43477c;
}

#login-splash .container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.5);
  }
  50% {
    transform: translate3d(-20%, 0, 0) scale(1.5);
  }
  75% {
    transform: translate3d(-25%, -25%, 0) scale(1.5);
  }
  100% {
    transform: translate3d(0%, 0, 0) scale(1);
  }
}

#signup-page .bg {
  z-index: -1;
}

#login-splash .bg,
#signup-page .bg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  /* -webkit-animation: zoom 60s ease infinite; */
  /* animation: zoom 60s ease infinite; */
  background: url(/assets/img/hero-bg.jpg) center center no-repeat;
  background-size: cover;
  /* z-index: -1; */
  opacity: 0.4;
  background-attachment: fixed;
}

#login-sidebar {
  width: 620px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: #202340;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-in-out;
  color: #fff;
  padding: 5rem 9rem 0rem 9rem;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
}
@media (max-width: 600px) {
  #login-sidebar {
    width: 100%;
    padding: 2rem 3rem;
  }
}

@media (max-width: 600px) {
  #login-sidebar img {
    max-width: 100%;
  }
}

#login-sidebar form {
  padding: 3rem 0;
}

#login-sidebar .intro,
#signup-page .intro {
  font-size: 1rem;
}

#login-sidebar .message,
#signup-page .message {
  font-size: 0.8125rem;
}

#login-sidebar input,
#login-sidebar button,
#signup-page input,
#signup-page button {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

#login-sidebar input,
#signup-page input {
  padding: 15px;
  color: #fff;
  border: 0;
  border-bottom: 1px solid #fff;
  font-size: 0.875rem;
  background: transparent;
}

#login-sidebar input::-webkit-input-placeholder {
  color: #fff;
}

#login-sidebar button:hover,
#signup-page button:hover {
  color: #fff;
}

#login-sidebar #login-form .btn {
  width: 100%;
}

#login-sidebar .loading,
#signup-page .loading {
  width: 0;
  height: 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0073bc;
  transition: none;
}

#login-sidebar .logging-in button .loading,
#signup-page .logging-in button .loading {
  transition: width 0.4s linear;
  width: 100%;
}

.sr-only {
  display: none;
}

#login-sidebar input.form-error,
#signup-page input.form-error {
  border: 1px solid red;
}

p.error {
  color: red;
  font-size: 0.85rem;
}

@keyframes inputShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-6px) rotateY(-5deg);
  }
  37.5% {
    transform: translateX(5px) rotateY(4deg);
  }
  62.5% {
    transform: translateX(-3px) rotateY(-2deg);
  }
  87.5% {
    transform: translateX(2px) rotateY(1deg);
  }
  to {
    transform: translateX(0);
  }
}

#login-sidebar.form-error-shake,
#signup-page .container.form-error-shake {
  animation: inputShake 0.4s ease-in-out;
}

#login-sidebar button.google.btn.white,
#signup-page button.google.btn.white {
  display: flex;
  justify-content: space-around;
  padding: 0;
}

#login-sidebar button.google.btn.white:hover,
#signup-page button.google.btn.white:hover {
  background: #eee;
  color: #426288;
}

#login-sidebar button.google.btn.white span,
#signup-page button.google.btn.white span {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  align-content: center;
}
s #login-sidebar button.google.btn.white span img,
#signup-page button.google.btn.white span img {
  margin-right: 7px;
}

button.button-link {
  color: #6aaec4;
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0;
  display: inline;
  cursor: pointer;
}
