#not-found-section {
  height: 100vh;
  position: relative;
  overflow: hidden;
  color:#444;
}

#not-found-section h3 {
  margin-bottom: 1rem;
}

#not-found-section p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}


#not-found-section .container {
  width: 1300px;
  background: #fff;
  height: calc(100% - 180px);
  margin: 90px auto;
}


#not-found-section .half {
  width:50%;
  height:100%;
  padding: 30px;
  float:left;
}


#not-found-section .half.first {

}

#not-found-section .not-found-copy {
  max-width: 60%;
  height:100%;
  position: relative;
  margin:auto;
  padding-top:3.5rem;
}

#not-found-section .not-found-copy {
  padding-top:0;
  transform: translateY(-50%);
  top:calc(50% - 80px);
  position: relative;
  height:auto;
}


#not-found-section .half.second {
  background:#f7f7f7 url(./bg.jpg);
  background-size:cover;
}


#not-found-section .message {
  position: absolute;
  bottom:1rem;
  left:0;
  right:0;
  text-align: center;
}