/* colours */
:root {
  --brand-primary: #43477c;
  --brand-secondary: #1aa9ae;
  --bg-color: #43477c;
}

/* Responsive */
:root {
  --max-width: 1400px;
}

@media (min-width: 601px) {
  :root {
    --ptab: 601px;
  }
}
@media (min-width: 769px) {
  :root {
    --tab: 769px;
  }
}
@media (min-width: 1025px) {
  :root {
    --desk: 1025px;
  }
}
@media (min-width: 1201px) {
  :root {
    --wideDesk: 1201px;
  }
}
@media (max-width: 600px) {
  :root {
    --mobileOnly: 600px;
  }
}
@media (max-width: 768px) {
  :root {
    --ptabAndLess: 768px;
  }
}
@media (max-width: 1024px) {
  :root {
    --tabAndLess: 1024px;
  }
}
@media (max-height: 768px) {
  :root {
    --laptopHeightOnly: 768px;
  }
}

.page-heading {
  text-align: center;
  /* margin-top:70px; */
  height: 500px;
  position: relative;
  overflow: hidden;
  color: #fff;
  background-size: cover;
  background-position: 50%;
}
.page-heading.small-header {
  height: 275px;
  padding-bottom: 1rem;
}
.page-heading .container {
  position: relative;
  top: calc(54px + 50%);
  transform: translateY(-50%);
}
@media (max-width: 600px) {
  .page-heading h1 {
    font-size: 30px;
  }
  .page-heading .container {
    width: 100%;
  }
}
.page-heading:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--brand-primary);
  opacity: 0.75;
}
.page-heading .narrow-wrap {
  float: left;
  text-align: left;
  max-width: 515px;
}
.page-heading h2 {
  margin-bottom: 0;
  color: #fff;
  opacity: 0;
  animation: fadeTextIn 0.6s 0.2s linear forwards;
}
@media (max-width: 600px) {
  .page-heading h2 {
    font-size: 1.7rem;
  }
}
.page-heading p {
  margin-top: 0.5rem;
  color: #fff;
  font-size: 1.4rem;
  opacity: 0;
  animation: fadeTextIn 0.6s 0.2s linear forwards;
}
@media (max-width: 600px) {
  .page-heading p {
    font-size: 1.1rem;
  }
}
.page-heading .btn {
  font-size: 1rem;
  margin-top: 1rem;
}
@keyframes fadeTextIn {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
#page-title {
  color: var(--brand-primary);
}

.page-wrap .narrow-wrap {
  max-width: 600px;
  margin: auto;
}

.page-heading .container {
  position: relative;
  top: calc(24px + 50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.page-wrap .container {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.page-wrap .panel {
  background: #fff;
  color: #43477c;
  padding: 3rem 0;
}