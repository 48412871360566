@media (max-width: 600px) {
  html,
  body {
    overflow: hidden;
  }
}

.App {
  width: 100%;
  height: 100vh;
}
@media (max-width: 600px) {
  .App {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .leaflet-bottom {
    display: none;
  }
}

.sr-only {
  display: none;
}

/*#utility-bar {
  display: flex;
}

#utility-bar button {
  display: flex;
  flex:1;
  padding: 15px 0;
  justify-content: center;
}*/

.open-project-options .creation-menu__options button {
  opacity: 1;
  transform: translate3d(0px, 0px, 0);
}

.open-project-options .creation-menu__options button:first-child {
  transition-delay: 0.1s;
}

.back-link i {
  margin-right: 10px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
  background-size: 15px 15px;
}

/* TODO: disabled look and refactor*/
a.btn,
button.btn,
input[type='submit'] {
  text-align: center;
  border: 1px solid #6aaec4;
  padding: 1rem 60px;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.25s linear;
  margin-bottom: 1rem;
  border-radius: 100px;
  font-weight: 500;
  font-size: 0.875rem;
  background-color: #6aaec4;
  color: #fff;
  cursor: pointer;
}

a.btn:hover,
button.btn:hover,
input[type='submit']:hover {
  background-color: #fff;
  color: #6aaec4;
}

a.btn.white,
button.btn.white,
input[type='submit'].white {
  text-align: center;
  border: 1px solid #dadbe0;
  background: #fff;
  padding: 1rem 60px;
  color: #426288;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.25s linear;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 100px;
}

a.btn.white:hover,
button.btn.white:hover,
input[type='submit'].white:hover {
  color: #88eaf2;
  background: #202340;
}

a.btn.solid,
button.btn.solid,
input[type='submit'].solid,
.button.solid {
  border: 1px solid #6aaec4;
  padding: 1rem 60px;
  color: #fff;
  background: #6aaec4;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.25s linear;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: 1rem;
  position: relative;
  font-size: 0.875rem;
  border-radius: 100px;
}

a.btn.solid:hover,
button.btn.solid:hover,
input[type='submit'].solid:hover,
.button.solid:hover {
  background: #fff;
  color: #6aaec4 !important;
}

a.btn.solid:hover,
input[type='submit'].solid:disabled {
  cursor: default;
  border-color: transparent;
  background: #eee;
  color: #bbb !important;
}

/* Global button styling */

.btn-primary {
  transition: all 0.25s linear;
  padding: 1rem 60px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 0.875rem;
  cursor: pointer;
}

.btn-primary-solid {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.btn-primary-solid:hover {
  background-color: #fff;
  color: var(--primary-color);
}

.btn-primary-white {
  border: 1px solid var(--primary-color);
  background-color: #fff;
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}

.btn-primary-white:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-secondary-white {
  border: 1px solid #ccc;
  background-color: #fff;
  color: var(--secondary-color-light);
  font-weight: 500;
  text-transform: uppercase;
}

.btn-secondary-white:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color-highlight);
}

.btn-primary-distructive {
  border: 1px solid #ccc;
  background-color: #fff;
  color: var(--secondary-color-light);
  font-weight: 500;
  cursor: pointer;
}

.btn-primary-distructive:hover {
  border: 1px solid red;
  background-color: red;
  color: #fff;
}

.btn-primary-solid:disabled,
btn-secondary-white:disabled,
.btn-primary-white:disabled,
.btn-primary-distructive:disabled {
  border: 1px solid var(--disabled-color);
  background-color: #fff;
  color: var(--disabled-color);
}

.btn-close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  cursor: pointer;
  z-index: 3000;
  flex-shrink: 0;
}

.btn-link {
  color: var(--default-font-color);
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0;
  display: inline;
  cursor: pointer;
}

.btn-link:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.btn-link.active {
  color: var(--primary-color);
}

#dashboard-content {
  position: relative;
}

#dashboard-navigation .button.solid {
  width: 100%;
  color: #fff;
}

.revise-search {
  position: absolute;
  top: 10px;
  transform: translate3d(-50%, -200%, 0);
  left: 50%;
  min-width: 150px;
  background: #fff;
  color: #424777;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  padding: 8px 20px;
  border-radius: 3px;
  transition: all 0.3s ease;
  z-index: 2000;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 600px) {
  .revise-search {
    top: 70px;
  }
}

.revise-search.visible {
  transform: translate3d(-50%, 0, 0);
}

.revise-search.visible.clicked {
  transform: translate3d(-50%, -200%, 0);
}

.project-category {
  color: #fff;
  background: #6aaec4;
  display: inline-block;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 8px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.project-category.structural {
  background: #cc005e;
}

.project-category.agricultural {
  /* background: #cc005e; */
}

.project-category.nature {
  background: #3077b6;
}

.fa-map-marker.structural {
  color: #cc005e !important;
}

.fa-map-marker.agricultural {
  color: #6aaec4 !important;
}

.fa-map-marker.nature {
  color: #3077b6 !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 36px;
  height: 36px;
  margin: 3px;
  border: 3px solid #ccc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ccc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.rc-progress-line {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 1rem 1rem;
  background-color: #ccc;
  border-radius: 20px;
  animation: progress-bar-stripes 1s linear infinite;
}

.rc-progress-line-trail {
  stroke-opacity: 0;
}

.project-content {
  display: block;
  /* border-bottom: 1px solid #ececec; */
  margin: 0;
  padding: 1.5rem 3rem;
  overflow: hidden;
  text-align: left;
}
@media (max-width: 600px) {
  .project-content {
    white-space: normal;
    display: inline-block;
    border-bottom: 0;
    margin: 0 17px;
    background: #fff;
    border-radius: 5px;
    bottom: 10px;
  }
}

.project-content i {
  margin-left: 0.5rem;
}

.project-content .btn {
  width: 100%;
}

.project-content .btn:disabled {
  opacity: 0.3;
}

.list-controls {
  font-size: 1.1rem;
  float: right;
  z-index: 100;
}

.project-name {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.25rem;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.operator_assigned {
  margin-left: 1rem;
}

.operator_assigned .fa-user {
  color: #43477c;
}

.project-description {
  float: left;
  font-size: 0.8rem;
  margin: 0;
  width: 100%;
}

.project-details {
  position: relative;
}

.project-details p {
  /* font-size:0.8rem; */
  margin: 0;
}

.additional-detail p {
  display: inline-block;
  float: left;
  clear: both;
}

.project-details .project-status {
  top: 0px;
  right: 0;
  position: absolute;
  font-size: 0.8125rem !important;
  font-weight: 400;
  color: #bbbbbb !important;
  margin-left: 1rem;
}

.project-details .additional-detail {
  overflow: hidden;
}

.view-project {
  font-size: 0.7rem;
  margin-top: 0;
  border: 0;
  color: #2fce9b;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0;
}

.full-width {
  width: 100%;
  margin-top: 2rem;
}

.project-notes {
  /* border-top:1px solid #ccc; */
  /* border-bottom:1px solid #ccc; */
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  float: left;
  width: 100%;
}

.project-notes p {
  font-size: 0.75rem;
}

.project-card .project-description {
  float: left;
  font-size: 0 0.8rem;
  margin: 0;
  width: 100%;
}

.project-card p {
  margin: 0;
}

.project-card .flight-plan-count {
  font-size: 0.75rem;
  color: #aaa;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.tabs {
  width: 100%;
  margin: 1rem 0 0 0;
  display: flex;
  border-bottom: 1px solid #ececec;
  padding: 0 2rem 1.5rem 2rem;
}

.tabs .tab {
  float: left;
  font-size: 0.8rem;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.tabs .tab {
  float: left;
  font-size: 0.8rem;
  margin: 0 1rem;
  color: #426288;
  font-weight: 500;
  cursor: pointer;
}

.tabs .tab:hover {
  color: #6aaec4;
}

.tabs .tab.active {
  color: #6aaec4;
}

#project-list .view-project {
  font-size: 0.7rem;
  margin-top: 0px;
  border: 0;
  color: #6aaec4;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0;
  background: transparent;
}

.no-results {
  width: 100%;
  padding: 1.5rem 3rem;
  float: left;
  text-align: center;
}

.no-results .status-message {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.no-results #drone-icon {
  padding-top: 1rem;
}

.no-results .no-result-message {
  padding-top: 1.5rem;
}

.no-results .title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.25rem;
  text-align: center;
}

.project-distance {
  color: #707070;
  font-size: 0.75rem;
  position: relative;
  padding-left: 15px;
  margin-bottom: 0.1rem !important;
}

.project-distance svg {
  stroke: #426288;
  fill: transparent;
  width: 9px;
  height: 13px;
  position: absolute;
  left: 0;
  top: 1px;
}

.project-time,
.project-author {
  color: #707070;
  font-size: 0.75rem;
  position: relative;
  padding-left: 15px;
}

.project-author {
  width: fit-content;
}

.project-time svg {
  /* stroke:#426288; */
  fill: #426288;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 3px;
}

.project-author svg {
  stroke: #426288;
  fill: #426288;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 3px;
  stroke-width: 16px;
}

#dashboard-content {
  width: calc(100% - 735px);
  height: 100%;
  float: left;
}

.collapsible-menu-is-closed #dashboard-content {
  width: calc(100% - 735px + 230px);
}

#dashboard-navigation {
  width: 735px;
  height: 100%;
  float: left;
  background: #fff;
  position: relative;
  overflow: hidden;
  transition: width 0.2s linear;
}

.collapsible-menu-is-closed #dashboard-navigation {
  width: calc(735px - 230px);
}

#collapsible-menu {
  white-space: nowrap;
}

.collapsible-menu .logo-wrap {
  width: 197px;
  position: relative;
  margin: auto;
}

.collapsible-menu .white-label {
  width: 150px;
}

.collapsible-menu .white-label .logo {
  width: 150px;
  height: auto !important;
  position: relative;
  margin: auto;
}

.collapsible-menu .logo img {
  position: absolute;
  left: 0;
  top: 0;
  width: 197px;
}

.collapsible-menu-is-closed .logo-wrap {
  left: -14px;
  width: 30px;
  overflow: hidden;
}

.collapsible-menu-is-closed .white-label.logo-wrap img {
  width: 30px;
}

.collapsible-menu-is-closed .collapsible-menu .user-meta {
  margin-left: -14px;
}

.collapsible-menu-is-closed .collapsible-menu .user-meta img {
  width: 30px;
  height: 30px;
}

.collapsible-menu-is-closed .collapsible-menu .user-meta p {
  display: none;
}

.collapsible-menu-is-closed #collapsible-menu .add-button .btn {
  padding: 0px;
  overflow: hidden;
  min-height: 38px;
  min-width: 38px;
}

.collapsible-menu-is-closed #collapsible-menu .add-button .btn:hover {
  color: #fff;
  background-color: #6aaec4;
}

.collapsible-menu-is-closed #collapsible-menu .add-button .btn span {
  display: none;
}

.collapsible-menu-is-closed #collapsible-menu .add-button .add-icon {
  margin-left: -11px;
}

.collapsible-menu-is-closed #collapsible-menu .collapsible-menu__items {
  margin-left: -6px;
}

.collapsible-menu-is-closed #collapsible-menu .collapsible-menu__items span {
  display: none;
}

.collapsible-menu-is-closed #collapsible-menu .collapsible-menu__items li a,
.collapsible-menu-is-closed #collapsible-menu .collapsible-menu__items li button {
  min-height: 39px;
}

.verify-address {
  background: #4a4d80;
  padding: 1rem 1.7rem;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  color: #fff;
}

.verify-address button {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  border-bottom: 1px dotted #88eaf2;
  color: #88eaf2;
  cursor: pointer;
}

#dashboard-navigation .container {
  padding: 1.7rem;
  text-align: center;
}
@media (max-width: 600px) {
  #dashboard-navigation .container {
    padding: 0;
  }
}

#dashboard-navigation header.container {
  background: #fff;
  padding: 1rem 0 0.7rem 0;
  line-height: 1;
  position: relative;
  border-bottom: 1px solid #eee;
}
@media (max-width: 600px) {
  #dashboard-navigation header.container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
}

#dashboard-navigation .sidebar-content .container {
  text-align: left;
}

.sidebar-content {
  background: #fff;
  color: #444;
  height: calc(100% - 52px);
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  right: 0;
  top: 0;
  width: 435px;
  margin-top: 52px;
}
@media (max-width: 600px) {
  .sidebar-content {
    width: calc(100vw - 70px);
  }
}

.back-link {
  /* padding-left:30px; */
  /* padding-top:0px; */
  padding-right: 30px;
  color: #999;
  text-transform: uppercase;
  float: left;
  /* position: relative; */
  /* bottom: 30px; */
  cursor: pointer;
  /* width: 175px; */
  left: 0px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 600px) {
  .back-link {
    bottom: 150px;
    left: auto;
    padding: 3px 25px;
    right: 15px;
  }
}

input[type='checkbox'] {
  margin-right: 0.5rem;
}

.password-container {
  position: relative;
}

.password-container .material-icons {
  position: absolute;
  right: 5px;
  top: 11px;
  color: #999;
}

#login-sidebar .password-container input,
#signup-page .password-container input {
  padding-right: 40px;
}
