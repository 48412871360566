.narrow-wrap-700 {
  max-width: 700px;
  margin: auto;
}

.question {
  margin-bottom: 0.5rem;
}
.question header {
  padding-left: 40px;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  position: relative;
  border-bottom: 1px solid #ccc;
}
.question header h2 {
  margin: 0;
}
.question header h2:hover {
  color: #1aa9ae;
}
.question header svg {
  width: 30px;
  height: 30px;
  fill: #1aa9ae;
  position: absolute;
  left: 0;
  top: 3px;
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}
.question h2 {
  font-size: 1.75rem;
}
.question.visible svg {
  transition: transform 0.3s linear;
  transform: rotate(0deg);
}
.question.visible .answer-content {
  max-height: 300vh;
  padding: 1rem 40px 1rem 40px;
  transition: max-height 0.4s ease-in-out;
}
.question .answer-content {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0s ease-in-out;
}
.question .answer-content h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
.question .answer-content ul {
  margin-bottom: 2rem;
}